<template>
    <section class="LoginSection">
        <img src="images/Poster.jpg" alt="" />
        <div class="BackgroundOverlay">
			<div class="Container">
				<div class="Box">
					<div class="LoginLogo"><img src="images/LoginLogo.png" srcset="images/LoginLogoRetina.png 2x" alt="" /></div>
					<h1>Benvenuto</h1>
				</div>
				<div class="Box">
					<div class="Binder Big">
                        <form class="Form LoginPanel">
                            <fieldset>
								<legend>Accedi come società</legend>
                                <div class="FormRow">
                                    <div class="FormBoxWide">
                                        <label>Username</label>
                                        <input placeholder="Username/email" type="text" v-model="usernameB2b" /> </div>
                                </div>
                                <div class="FormRow">
                                    <div class="FormBoxWide">
                                        <label>Password</label>
                                        <input placeholder="Password" type="password" v-model="passwordB2b" @keypress.enter="SetCurrentShop('b2b')"/>
                                    </div>
                                </div>
                                <div class="FormRow">
                                    <div class="FormBoxWide">
                                        <label class="PasswordRecovery">
                                            <router-link :to="{name: 'RecuperaPasswordB2b'}">Password dimenticata?</router-link>
                                        </label>
                                        <br>
                                        <div class="Button">
                                            <input type="button" value="Login" @click="SetCurrentShop('b2b');"/> </div>
                                    </div>
                                </div>
                            </fieldset>
                        </form>

                        <form class="Form LoginPanel">
                            <fieldset>
								<legend>Accedi come tesserato</legend>
                                <div class="FormRow">
                                    <div class="FormBoxWide">
                                        <label>Username</label>
                                        <input placeholder="Username/email" type="text" v-model="usernameB2c" />
                                    </div>
                                </div>
                                <div class="FormRow">
                                    <div class="FormBoxWide">
                                        <label>Password</label>
                                        <input placeholder="Password" type="password" v-model="passwordB2c" @keypress.enter="SetCurrentShop('b2c')"/>
                                    </div>
                                </div>
                                <div class="FormRow">
                                    <div class="FormBoxWide">
                                        <label class="PasswordRecovery">
                                            <router-link :to="{name : 'RecuperaPasswordB2c'}">Password dimenticata?</router-link>
                                        </label>
                                        <br>
                                        <div class="Button">
                                            <input type="button" value="Login" @click="SetCurrentShop('b2c');"/> </div>
                                    </div>
                                </div>
                            </fieldset>
							<div class="Claim">Sei un giocatore e non hai ancora accesso allo store?<br>
                            <router-link :to="{'name' : 'Registrazione'}">Registrati come tesserato</router-link></div>
                        </form>
                    </div>
                </div>
				<div class="Box">
                    <div class="TxtEditor">
						<p>L'utilizzo di questo sito è soggetto all'accettazione dei <a href="/#/pagine/termini-e-condizioni">Termini e Condizioni</a>, della <a href="#/pagine/privacy-policy">Privacy Policy</a> e della <a href="#/pagine/cookie-policy">Cookie Policy</a> </p>
					</div>
				</div>
            </div>
        </div>
    </section>
</template>

<script>
import AuthService from "@/services/auth.service.js";
import store from '@/store';

export default {
    name: "LoginPage",
    data() {
        return {
            shop: "",
            usernameB2b: "",
            passwordB2b: "",
            usernameB2c: "",
            passwordB2c: "",
            isLoading: false,
            Logo: process.env.VUE_APP_LOGO,
            LogoRetina: process.env.VUE_APP_LOGO_RETINA,
            LoginBackground: process.env.VUE_APP_LOGIN_BACKGROUND
        }
    },
    mounted:function(){
        this.$store.dispatch({
            type: 'setLoader',
            value: false
        });
    },
    methods: {
        CallLogin() {
            this.$store.dispatch({
                type: 'setLoader',
                value: true
            });

            let username = "";
            let psw = "";
            
            if(this.shop == 'b2c'){
                username = this.usernameB2c;
                psw = this.passwordB2c;
            }else{
                username = this.usernameB2b;
                psw = this.passwordB2b;
            }
            this.$store.dispatch({
                type: 'login',
                username: username,
                password: psw,
                shop: this.shop
            }).then(
                (data) => {   
                this.$store.dispatch({
                    type: 'setLoader',
                    value: true
                });
                this.$router.push("/home");
                },
                (error) => {
                    this.$store.dispatch({
                        type: 'setLoader',
                        value: false
                    });
                    this.$toast.error("Credenziali Errate!");
                }
            ).finally(() =>{
                this.$store.dispatch({
                    type: 'setLoader',
                    value: false
                });
            })
            ;
        },
        SetCurrentShop(env) {
            this.shop = env;
            this.CallLogin();
        }
    },
    beforeRouteEnter(to, from, next) {
        let token = store.getters.getToken;
        let shop = store.getters.getShop;
        if (token) {
            next({
                name: "Home"
            });
            AuthService.checkLogin(token, shop).then(
                    data => {
                        if (data) {
                            next({
                                path: "/" + shop
                            });
                        } else {
                            next();
                        }
                    }
                )
                .catch(error => {
                    this.$toast.error("Erorre");
                    next()
                });
        } else {

            next();
        }
    }
}
</script>

<style scoped>
.LoginSection h2, .LoginSection .TxtEditor{color:#ffffff;}
.TxtEditor a {
    color: #fff;
}
.TxtEditor {
        margin-top: 20px;
}
.Disabled{
	background-color: grey !important;
	background-image: linear-gradient(grey,grey) !important;
}
.MyOpacity{
    opacity: 0.7;
}
</style>